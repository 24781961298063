import { getUserAPI } from "../../service/user";
import { SET_USER, LOGOUT_USER } from "../constants";

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const logoutUser = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  window.location.href = "/login";
  return {
    type: LOGOUT_USER,
  };
};
export const setUserInfo = (userInfo) => (dispatch) => {
  dispatch(setUser(userInfo));
}
export const getUserFromAPI = () => (dispatch) => {

  getUserAPI().then((user) => {
    dispatch(setUser(user));
  });
};
