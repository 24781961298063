import html2canvas from "html2canvas";
import {presentationPostMethod, extractResult} from "../service/presentationMode";
import {isMobile} from "react-device-detect";

export const isDeviceDesktopAndHaveTouchSupport = () => {
    return !isMobile &&'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0;
};

export const dataURLToBinary = (dataURL) => {
    var arr = dataURL.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return u8arr;
}
export const toBase64NoCors = (originalSrc) => {
    // const corsProxy = 'https://proxy.cors.sh/';
    const corsProxy = `${process.env.REACT_APP_MEDIA_CONTENT_URL}/api/Content/Image/Convert/Base64?url=` ;

    return fetch(corsProxy + originalSrc)
        .then(response => response.text())
        .then((response) => {
            return  response
            // return new Promise((resolve, reject) => {
            //     const reader = new FileReader();
            //     reader.readAsDataURL(response);
            //     reader.onloadend = () => {
            //         console.log(reader.result,'reader.result)')
            //         resolve(reader.result);
            //     };
            //     reader.onerror = reject;
            // });
        });
};
export const locales = {
    1: 'hy',
    2: 'en',
}
export const getLocaleKey = id => locales[id]
export const handleCaptureTypeBlob = (callBack, elementId, elementRef) => {
    if (elementId !== 'map') {
        const loadImages = async (element) => {
            const images = element.querySelectorAll('img');
            const promises = Array.from(images).map((img) => {
                const newImage = img
                const originalSrc = newImage.src;
                if (!originalSrc.startsWith('data:image') && originalSrc.startsWith('https://img.youtube.com/vi/')) {
                    return  toBase64NoCors(originalSrc).then((base64data) => {
                        newImage.src = base64data;
                        return base64data
                    })
                }
                return new Promise((resolve, reject) => {
                    const originalSrc = newImage.src;
                    const timestampedSrc = originalSrc.startsWith('data:image') ? originalSrc : `${originalSrc}?v=${new Date().getTime().toString().split(0,4)}`;
                    const newImg = new Image();
                    newImg.crossOrigin = 'anonymous';
                    newImg.src = timestampedSrc;
                    newImg.onload = () => {
                        newImage.src = timestampedSrc;
                        resolve();
                    };
                    newImg.onerror = reject;
                });
            });
            return Promise.all(promises);
        };
        const adjustTextContent = (element) => {
            const titleElements = element.querySelectorAll('.article-read-more-header__title h2');
            titleElements.forEach(title => {
                title.innerHTML = title.innerHTML.replace(/և/g, 'ԵՒ');
            });
        };

        const targetElement = elementRef?.current || document.getElementById(elementId) || document.getElementById('root');
        if (targetElement) {
            adjustTextContent(targetElement);
            loadImages(targetElement).then(() => {
                setTimeout(() => {
                    html2canvas(targetElement, { useCORS: true, allowTaint: false })
                        .then((canvas) => {
                            canvas.toBlob(callBack, 'image/jpeg');
                        })
                        .catch((error) => {
                            console.error('Error capturing screenshot with html2canvas: ', error);
                        });
                }, 500);
            }).catch((error) => {
                console.error('Error loading images: ', error);
            });
        }
    } else {
        const targetElement = elementRef?.current || document.getElementById(elementId) || document.getElementById('root');
        if (targetElement) {
            const stickyNoteContainers = document.querySelectorAll('.sticky_note_container');
            const clonedContainers = [];

            stickyNoteContainers.forEach(container => {
                const clone = container.cloneNode(true);
                targetElement.appendChild(clone);
                clonedContainers.push(clone);
            });
            html2canvas(targetElement, { useCORS: true, allowTaint: false })
                .then((canvas) => {
                    canvas.toBlob(callBack, 'image/jpeg');
                    clonedContainers.forEach(clone => {
                        targetElement.removeChild(clone);
                    });
                })
                .catch((error) => {
                    console.error('Error capturing screenshot with html2canvas: ', error);
                });
        }
    }
};
export const youtube_parser =(url) =>{
    return url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
    // const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    // const match = url.match(regExp);
    // console.log(match,'match')
    // return (match && match[7].trim().length==11)? match[7].trim() : false;
}
export const handleYoutubeImagePathGen = (path) => {
    return `https://img.youtube.com/vi/${youtube_parser(path)}/default.jpg`
 // return `https://i.ytimg.com/vi_webp/${youtube_parser(path)}/maxresdefault.webp`
}
export const uploadImage = (blob,alt,title,access) => {
    const url =`${process.env.REACT_APP_MEDIA_CONTENT_URL}/api/Upload`;
    var formData = new FormData();
    formData.append('content', blob,'capturedImage.jpeg');
    formData.append('title', title || 'capturedImage.jpeg')
    formData.append('alt', alt || title || 'capturedImage.jpeg')
    formData.append('access', access || "PublicRead")
    return presentationPostMethod(url,formData)
            .then(extractResult)
}

export const generateUniqueId = () => Math.random().toString(36).substr(2, 9);
