import React, {useEffect, useLayoutEffect} from "react";
import MiniGallery from "../miniGallery";
import AnimationGallery from "../animationGallery";
import Plus from "../UI/Plus";
import Minus from "../UI/Minus";
import CloseDarkModal from "../UI/CloseDarkModal";
import AudioPlayer from "../audioPlayer";
import {
  getArticlePopUpFontSize,
  getCategoriesData,
  getScreenShotLoadingST,
  getSelectedArticleDataAll
} from "../../store/selectors";
import { connect } from "react-redux";
import {
  closeArticlePopup,
  collapsArticlePopup,
} from "../../store/actions/articles";
import galleryImgIcon from "../../assets/imgs/gallery-img-icon.svg";
import galleryVideoIcon from "../../assets/imgs/gallery-video-icon.svg";
import gallery360Icon from "../../assets/imgs/gallery-360-icon.svg";
import { setPainterStart } from "../../store/actions/painterStart";
import { setSearch } from "../../store/actions/search";
import { setMapStyle } from "../../store/actions/mapStyle";
import ArticlePopup from "../articlePopup";
import { useRef } from "react";
import { useState } from "react";
import {DangerouslySetInnerHTML} from "./ArticleReadMoreComponents/ArticleReadMoreComponents";
import {ZoomInFont} from "./ZoomInFont";
import {setArticlePopUpFontSize} from "../../store/actions/articlesAll";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {setIsReadMoreState} from "../../store/actions/mapStateAction";

const ArticleItem = (props) => {
  const {
    article,
    categories,
    map,
    collapsArticlePopup,
    closeArticlePopup,
    toggleGallery,
    toggleShowArticlePopup,
    stopPinAnimation,
    fontSize,
    setFontSize,
    setIsReadMoreState,
    showReadMore,
    selectedArticleDataAll,
    getScreenShotLoadingST,
    setShowArticlePopup
  } = props;


  const scrollZone = useRef();
  const screenWidth = window.innerWidth;
  const {t} = useTranslation()
  const lng = i18n.language === 'hy' ? 1 : 2
  const content = article?.articleData?.content.find(
      (item) => item.languageId === lng
  )
  useEffect(() => {
    if (article?.articleData?.gallery?.[0]?.scrollY) {
      const scrollEl = document.getElementById(`hero__descr_${article.id}`);
      scrollEl.scrollTop = article.articleData.gallery?.[0]?.scrollY;
    }
  }, [article]);

  useEffect(() => {
    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      if (newScreenWidth < 1024 && fontSize === null) {
        setFontSize(20);
      } else if (fontSize === null) {
        setFontSize(14);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  useEffect(() => {
    if( getScreenShotLoadingST)  {
      if(showReadMore) {
        return toggleShowArticlePopup(article.id, selectedArticleDataAll?.id !== article.id)
      }
      setShowArticlePopup(false)
    }
  },[showReadMore])

  // useEffect(() => {
  //   if(closeArticlePopup) {
  //     setShowArticleReadMore(false)
  //   }
  // }, [closeArticlePopup])

  const configureFontSize = (type) => {
    if (type === 'plus' && fontSize < (screenWidth < 1024 ? 26 : 20)) {
      setFontSize(fontSize + 2);
    }
    if (type === 'minus' && fontSize > (screenWidth < 1024 ? 20 : 14)) {
      setFontSize(fontSize - 2);
    }
  };

  const galleryContainItems = {
    video: false,
    image: false,
    audio: false,
    R360: false,
  };
  article.articleData?.gallery?.forEach((value, index, self) => {
    if (self.findIndex((el) => el.type === value.type) === index) {
      switch (value.type) {
        case 0:
          galleryContainItems.image = true;
          break;
        case 1:
          galleryContainItems.image = true;
          break;
        case 2:
          galleryContainItems.R360 = true;
          break;
        case 3:
          galleryContainItems.video = true;
          break;
        case 4:
          galleryContainItems.audio = true;
          break;
      }
    }
  });

  const gallery =
        !!content?.featuredImage
      ? [
          ...[
            {
              mediaId: null,
              path: content?.featuredImage,
            },
          ],
          ...article?.articleData?.gallery,
        ]
      : article?.articleData?.gallery;
  useEffect(() => {
    props.setPainterStart(false);
    props.setSearch(false);
  }, []);

  useLayoutEffect(() => {
    map?.fire('closeMapboxGlDrawPopupsAndSelection');
  }, []);

  const onChangePlayer = (type, state) => {
    if (!article.articleData.gallery?.[0]["saveData"]) {
      article.articleData.gallery[0].saveData = {
        isPlay: null,
        currentTime: null,
        volumeToggle: null,
        volume: null,
      };
    }
    switch (type) {
      case "playing":
        article.articleData.gallery[0].saveData["playing"] = state;
        break;
      case "currentTime":
        article.articleData.gallery[0].saveData["currentTime"] = state;
        break;
      case "volumeToggle":
        article.articleData.gallery[0].saveData["volumeToggle"] = state;
        break;
      case "volume":
        article.articleData.gallery[0].saveData["volume"] = state;
        break;
      default:
        break;
    }
  };

  return (
    <div
      key={article.id}
      className={`hero ${!article?.show ? "minimized" : ""}`}
    >
      <div className="hero__info-wrapper">
        <div className="hero__header">
          <h2 className="hero__title">
            {
              !article?.show
              ? content?.shortTitle
              : content?.mainTitle
            }
          </h2>
          <div className="hero__btns">
            <button
              className="hero__font"
              type="button"
              onClick={() => configureFontSize('minus')}
            >
              <ZoomInFont active={fontSize !== (screenWidth < 1024 ? 20 : 14)}/>
            </button>
            <button
              className="hero__font"
              type="button"
              onClick={() => configureFontSize('plus')}
            >
              <ZoomInFont size='big' active={fontSize !== (screenWidth < 1024 ? 26 : 20)}/>
            </button>
            <button
              className="hero__collapse"
              type="button"
              onClick={() => {
                collapsArticlePopup(article.id);

                    setTimeout(() => {
                      const scrollEl = document.getElementById(
                          `hero__descr_${article.id}`
                      );

                  scrollEl.scrollTop = article.articleData.gallery?.[0]?.scrollY;
                }, 0);
              }}
            >
              {!article.show ? <Plus /> : <Minus />}
            </button>
            <button
              className="hero__close"
              type="button"
              onClick={() => {
                setIsReadMoreState(false)
                closeArticlePopup(article.id);
                stopPinAnimation();
              }}
            >
              <CloseDarkModal />
            </button>
          </div>
        </div>
        <div className="hero__media-wrapper popup-image-section">
          {gallery?.length > 0 ? (
            <AnimationGallery
              src={article?.articleData?.featuredImage}
              feautured={content?.featuredImage}
              gallery={gallery}
              openLightBox={(id) => toggleGallery(id)}
              isMinimized={!article.show}
              lng={lng}
            />
          ) : (
            <div className="hero__media_animation__box">
              <button className="hero__media" id="lightgallery" type="button">
                <img
                  src={require("../../assets/imgs/image_placeholder.png")}
                  alt="image_placeholder"
                />
              </button>
            </div>
          )}

            {/*{article.show && gallery.length > 0 ? (*/}
            {/*    <AnimationGallery*/}
            {/*        gallery={gallery}*/}
            {/*        openLightBox={(id) => toggleGallery(id)}*/}
            {/*    />*/}
            {/*) : (*/}
            {/*    <div className="hero__media_animation__box">*/}
            {/*        <button*/}
            {/*            className="hero__media"*/}
            {/*            id="lightgallery"*/}
            {/*            type="button"*/}
            {/*        >*/}
            {/*            {gallery.length > 0 ? (*/}
            {/*                <img*/}
            {/*                    src={gallery[0].path}*/}
            {/*                    alt="image_placeholder"*/}
            {/*                />*/}
            {/*            ) : (*/}
            {/*                <img*/}
            {/*                    src={require('../../assets/imgs/image_placeholder.png')}*/}
            {/*                    alt="image_placeholder"*/}
            {/*                />*/}
            {/*            )}*/}
            {/*        </button>*/}
            {/*        <div className="hero__media__liner-gradien"></div>*/}
            {/*    </div>*/}
            {/*)}*/}
            {
              <div className={"hero__media_icons"}>
                {galleryContainItems.image && (
                    <span>
                  <img
                      style={{ width: "20px", height: "20px" }}
                      src={galleryImgIcon}
                      alt="galleryImgIcon"
                  />
                </span>
                )}
                {galleryContainItems.video && (
                    <span>
                  <img
                      style={{ width: "20px", height: "20px" }}
                      src={galleryVideoIcon}
                      alt="galleryVideoIcon"
                  />
                </span>
                )}
                {galleryContainItems.R360 && (
                    <span>
                  <img
                      style={{ width: "20px", height: "20px" }}
                      src={gallery360Icon}
                      alt="galleryVideoIcon"
                  />
                </span>
              )}
            </div>
          }
          {/*<p className="hero__category">*/}
          {/*    {*/}
          {/*        categories.find(*/}
          {/*            (x) =>*/}
          {/*                x.id ===*/}
          {/*                article.articleData?.category[0].categoryId*/}
          {/*        )?.title*/}
          {/*    }*/}
          {/*</p>*/}
          {/*<div className="hero__media-items"></div>*/}
          {/*{article?.articleData?.content[0].externalUrl !== null ? (*/}
          {/*    <a*/}
          {/*        className="hero__more"*/}
          {/*        target={'_blank'}*/}
          {/*        href={article?.articleData?.content[0].externalUrl}*/}
          {/*        rel="noreferrer"*/}
          {/*    >*/}
          {/*        ԱՎԵԼԻՆ*/}
          {/*    </a>*/}
          {/*) : null}*/}
          <button
            className="hero__more"
            onClick={() => {
              toggleShowArticlePopup(article.id);
            }}
          >
            {t('more')}
          </button>
        </div>
        <div className="hero__info">
          <div className="hero__text padding-right-hero">
            <div
                style={{fontSize: `${fontSize}px`, lineHeight: `${fontSize >= 14 && fontSize < 22  ? 24 : 30}px`}}
              className="hero__descr"
              id={`hero__descr_${article.id}`}
              ref={scrollZone}
              onScroll={(e) => {
                article.articleData.gallery[0].scrollY = e.target.scrollTop;
              }}
            >

              <DangerouslySetInnerHTML content={content?.introText ?? ""}/>
            </div>
          </div>
        </div>
      </div>
      {article?.articleData?.gallery?.find((x) => x.type === 4) && (
        <AudioPlayer
          onChangePlayer={onChangePlayer}
          saveData={article?.articleData?.gallery?.[0]?.saveData}
        />
      )}
    </div>
  );
};
const mapStateTopProps = (state) => ({
  categories: getCategoriesData(state),
  fontSize: getArticlePopUpFontSize(state),
  selectedArticleDataAll: getSelectedArticleDataAll(state),
  getScreenShotLoadingST: getScreenShotLoadingST(state),

});

const mapDispatchToProps = {
  collapsArticlePopup,
  closeArticlePopup,
  setPainterStart,
  setSearch,
  setFontSize: setArticlePopUpFontSize,
  setIsReadMoreState: setIsReadMoreState,


};

export default connect(mapStateTopProps, mapDispatchToProps)(ArticleItem);
