import { useEffect } from 'react';
import PropTypes from 'prop-types';

import {getCurrentUser} from "../../../helpers";

const GTag = ({ children, location }) => {

  const sendGtagBYUserInfo = () => {
    const user = JSON.parse(localStorage.getItem('userInfo'))?.data
    window.gtag('set', { user_id: user.id })
    window.gtag('config', process.env.REACT_APP_GA_TAG_ID, {
      page_path: location.pathname,
    })
  }
  const userInfoSender = async () => {
    const accessToken = localStorage.getItem('accessToken')
    const isAdmin = localStorage.getItem('admin') === 'true'
    if (!accessToken || isAdmin) return window.gtag('config', process.env.REACT_APP_GA_TAG_ID)
    if (localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'))?.data) return sendGtagBYUserInfo()
    await getCurrentUser()
    sendGtagBYUserInfo()
  }

  useEffect(() => {
    userInfoSender()
  }, [location.pathname])

  return children;

};

GTag.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape().isRequired,
};

export default GTag;
